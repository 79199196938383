// typed API endpoints
export const API = {
  ANSWERS: '/api/answers',
  ASSESSMENT: '/api/assessment',
  CLASSES: '/api/classes',
  FEEDBACK: '/api/feedback',
  REGISTER: '/api/register',
  SESSIONS: '/api/sessions',
  STUDENTS: '/api/students',
  VERIFY: '/api/verify',
  ME: '/api/me',
};

// typed page URLs
export const PAGE = {
  ACCOUNT: '/account/',
  CLASSES: '/classes/',
  HOME: '/',
  LOGIN: '/account/login/',
  MODULES: '/modules/',
  REGISTER: '/account/register/',
  RESET_PASSWORD: '/account/reset-password/',
  START_SESSION: '/classes/{CLASS_ID}/start-session/',
  VERIFY: '/account/verify/',
};

// typed query string parameters
export const QUERY_PARAMS = {
  ACTIVITY_ID: 'activityId',
  CHANNEL_NAME: 'channelName',
  CLASS_ID: 'classId',
  MODULE: 'module',
  PAGE: 'page',
  SESSION_ID: 'sessionId',
  STUDENT_ID: 'studentId',
  OWNER: 'owner',
  GROUP_NAME: 'group',
  GROUP_CAPTAIN: 'captain',
};

// typed local storage keys
export const LOCAL_STORAGE_KEYS = {
  STUDENT_SESSION: 'CAH_SESSION_STUDENT',
  TEACHER_SESSION: 'CAH_SESSION_TEACHER',
};

// typed local storage properties
export const LOCAL_STORAGE_PROPS = {
  CLIENT_ID: 'clientId',
  STUDENT_ID: 'studentId',
  SESSION_ID: 'sessionId',
  CHANNEL_NAME: 'channelName',
  STUDENT_NAME: 'studentName',
  AVATAR: 'avatar',
  REMEMBER_ME: 'rememberMe',
};

// typed Ably message names
export const ABLY_MESSAGE_NAME = {
  ACTIVITY_CHANGE: 'activityChange',
  ACTIVITY_FINISH: 'activityFinished',
  ACTIVITY_PRESENT: 'activityPresent',
  CLASS_FINISHED: 'classFinished',
  FEEDBACK_FINISH: 'feedbackFinished',
  GROUP_ANSWER: 'groupAnswer',
  GROUP_ASSIGNMENT: 'groupAssignment',
  GROUP_PROGRESS: 'groupProgress',
  GROUP_READY: 'groupReady',
  NEXT_MODULE: 'nextModule',
  PAGE: 'page',
  RESET_COUNTER: 'resetCounter',
};

// typed year group URL path segments
export const YEAR_GROUP_PATHS = {
  FIVE_SIX: '5-6',
  SEVEN_EIGHT: '7-8',
  NINE_TEN: '9-10',
};

// typed activity IDs
export const ACTIVITY_IDS = {
  JOIN_SESSION: 'join-session',
  FIVE_SIX: {
    M1_1_POLL: 'y56.1.1_poll',
    M1_2_POLL: 'y56.1.2_poll',
    M1_3_POLL: 'y56.1.3_poll',
    M1_4_REFLECTION: 'y56.1.4_reflection',

    M2_1_ACTIVITY: 'y56.2.1_activity',
    M2_2_REFLECTION: 'y56.2.2_reflection',

    M3_1_ACTIVITY: 'y56.3.1_activity',
    M3_2_REFLECTION: 'y56.3.2_reflection',

    M4_1_POLL: 'y56.4.1_poll',
    M4_2_ACTIVITY: 'y56.4.2_activity',
    M4_3_REFLECTION: 'y56.4.3_reflection',

    M5_1_ACTIVITY: 'y56.5.1_activity',
    M5_2_ACTIVITY: 'y56.5.2_activity',
    M5_3_POLL: 'y56.5.3_POLL',
    M5_4_REFLECTION: 'y56.5.4_reflection',

    M6_1_QUESTION: 'y56.6.1_question',
    M6_2_QUESTION: 'y56.6.2_question',
    M6_3_ACTIVITY: 'y56.6.3_activity',
    M6_4_REFLECTION: 'y56.3.4_reflection',

    M7_1_QUESTION: 'y56.7.1_question',
    M7_2_ACTIVITY: 'y56.7.2_activity',
    M7_3_REFLECTION: 'y56.7.3_reflection',

    M8_1_QUESTION: 'y56.8.1_question',
    M8_2_QUESTION: 'y56.8.2_question',
    M8_3_ACTIVITY: 'y56.8.3_activity',
    M8_4_REFLECTION: 'y56.8.4_reflection',
  },
  SEVEN_EIGHT: {
    M1_1_CATEGORISE_STEREOTYPES: 'y78.1.1_categorise-stereotypes-78',
    M1_2_CREATE_YOUR_TEAM: 'y78.1.2_create-your-team-78',
    M1_3_STEREOTYPES_1_REFLECTION: 'y78.1.3_stereotypes-1-reflection-78',

    M2_1_STEREOTYPES_2_POLL: 'y78.2.1_stereotypes-poll-78',
    M2_2_STEREOTYPES_VIDEO_MOMENTS: 'y78.2.2_stereotypes-video-moments-78',
    M2_3_STEREOTYPES_2_REFLECTION: 'y78.2.3_stereotypes-2-reflection-78',

    M3_1_MEDIA_1_POLL: 'y78.3.1_media-1-poll-78',
    M3_2_MARK_IT_UP: 'y78.3.2_mark-it-up-78',
    M3_3_MEDIA_1_REFLECTION: 'y78.3.3_media-1-reflection-78',

    M4_1_MEDIA_2_POLL: 'y78.4.1_media-2-poll-78',
    M4_2_MEDIA_2_SOCIAL_SHARE: 'y78.4.2_media-2-social-share-78',
    M4_3_MEDIA_2_POLL_AFTER: 'y78.4.3_media-2-poll-after-78',
    M4_4_MEDIA_2_REFLECTION: 'y78.4.4_media-2-reflection-78',

    M5_1_DISCRIMINATION_1_IMAGE_REVEAL: 'y78.5.1_discrimination-1-image-reveal-78',
    M5_2_DISCRIMINATION_CHALK_TALK: 'y78.5.2_discrimination-2-chalk-talk',
    M5_3_DISCRIMINATION_POLL: 'y78.5.3_discrimination-2-poll',
    M5_5_DISCRIMINATION_1_REFLECTION: 'y78.5.5_discrimination-1-reflection-78',

    M6_1_DISCRIMINATION_2_CHART_1: 'y78.6.1_discrimination-2-chart-1-78',
    M6_2_DISCRIMINATION_2_CHART_2: 'y78.6.2_discrimination-2-chart-2-78',
    M6_3_DISCRIMINATION_2_CHART_3: 'y78.6.3_discrimination-2-chart-3-78',
    M6_4_DISCRIMINATION_2_CHART_4: 'y78.6.4_discrimination-2-chart-4-78',
    M6_5_DISCRIMINATION_2_BENEFIT_1: 'y78.6.5_discrimination-2-benefit-1-78',
    M6_6_DISCRIMINATION_2_BENEFIT_2: 'y78.6.6_discrimination-2-benefit-2-78',
    M6_7_DISCRIMINATION_2_BENEFIT_3: 'y78.6.7_discrimination-2-benefit-3-78',
    M6_8_DISCRIMINATION_2_BENEFIT_4: 'y78.6.8_discrimination-2-benefit-4-78',
    M6_9_DISCRIMINATION_2_REFLECTION: 'y78.6.9_discrimination-2-reflection-78',

    M7_1_ONLINE_HATE_1_POLL: 'y78.7.1_online-hate-1-poll-78',
    M7_2_ONLINE_HATE_1_IMAGE_CHOICE: 'y78.7.2_online-hate-1-image-choice-78',
    M7_3_ONLINE_HATE_1_REFLECTION: 'y78.7.3_online-hate-1-reflection-78',

    M8_1_ONLINE_HATE_2_THINK_FEEL_CARE: 'y78.8.1_online-hate-2-think-feel-care',
    M8_2_ONLINE_HATE_2_SUPPORT_NETWORK: 'y78.8.2_online-hate-2-support-network',
    M8_3_ONLINE_HATE_2_REFLECTION: 'y78.8.3_online-hate-2-reflection-78',
  },
  NINE_TEN: {
    M1_1_STEREOTYPES_1_ACTIVITY: 'y910.1.1_stereotypes-1-activity-910',
    M1_2_STEREOTYPES_1_REFLECTION: 'y910.1.2_stereotypes-1-reflection-910',

    M2_1_STEREOTYPES_2_ACTIVITY: 'y910.2.1_stereotypes-2-activity-910',
    M2_2_STEREOTYPES_2_REFLECTION: 'y910.2.2_stereotypes-2-reflection-910',

    M3_1_MEDIA_1_POLL: 'y910.3.1_media-1-poll-1-910',
    M3_2_MEDIA_1_POLL_2: 'y910.3.2_media-1-poll-2-910',
    M3_3_MEDIA_1_ACTIVITY: 'y910.3.3_media-1-activity-910',
    M3_4_MEDIA_1_REFLECTION: 'y910.3.4_media-1-reflection-910',

    M4_1_MEDIA_1_POLL: 'y910.4.1_media-2-poll-910',
    M4_2_MEDIA_1_ACTIVITY: 'y910.4.2_media-2-activity-910',
    M4_3_MEDIA_2_REFLECTION: 'y910.4.3_media-2-reflection-910',

    M5_1_DISCRIMINATION_1_ACTIVITY_1: 'y910.5.1_discrimination-2-activity-1-910',
    M5_2_DISCRIMINATION_1_POLL: 'y910.5.2_discrimination-2-poll-910',
    M5_3_DISCRIMINATION_1_ACTIVITY_2: 'y910.5.3_discrimination-2-activity-2-910',
    M5_4_DISCRIMINATION_1_REFLECTION: 'y910.5.4_discrimination-1-reflection-910',

    M6_1_DISCRIMINATION_2_ACTIVITY: 'y910.6.1_discrimination-2-activity-910',
    M6_2_DISCRIMINATION_2_REFLECTION: 'y910.6.2_discrimination-2-reflection-910',

    M7_1_ONLINE_HATE_1_ACTIVITY: 'y910.7.1_online-hate-1-activity-910',
    M7_2_ONLINE_HATE_1_REFLECTION: 'y910.7.2_online-hate-1-reflection-910',

    M8_1_ONLINE_HATE_2_ACTIVITY: 'y910.8.1_online-hate-2-activity-910',
    M8_2_ONLINE_HATE_2_POLL: 'y910.8.2_online-hate-2-poll-910',
    M8_3_ONLINE_HATE_2_REFLECTION: 'y910.8.3_online-hate-2-reflection-910',
  },
};

// typed Firebase Firestore collection names
export const FIREBASE_COLLECTIONS = {
  ANSWERS: 'answers',
  CLASSES: 'classes',
  FEEDBACK: 'feedback',
  SESSIONS: 'sessions',
  STUDENTS: 'students',
  TEACHERS: 'teachers',
  TEACHER_SIGNINS: 'teacher_signins',
};

export const FIREBASE_AUTH_RESPONSE = {
  TOO_MANY_REQUEST: 'auth/too-many-requests',
};
